* {
    margin: 0;
    /*padding: 0;*/
    padding-top: 10px;
}

body {
    background-color: rgb(249, 246, 250);
    color: blue;
    height: 1200;
}

main {
    margin: 100px 100px;
    width: 50%;
    float: left;
    position: relative;
}

.mybutton {
    background-color: rgb(199, 155, 240); /* background-color: blueviolet; */
    padding: 10px;
    margin: 10px;
    font-size: 20px;
}
.mybutton:hover {
    transform: scale(1.1);
}

.mybuttondisabled {
    background-color: rgb(106, 103, 109);
    padding: 10px;
    margin: 10px;
    font-size: 20px;
}

.mybuttonf {
    background-color: rgb(8, 245, 20);
    padding: 10px;
    margin: 10px;
    font-size: 20px;
    float : right;
}
.mybuttonf:hover {
    transform: scale(1.1);
}
.mybuttonfdisabled {
    background-color: rgb(1, 43, 8);
    padding: 10px;
    margin: 10px;
    font-size: 20px;
    float : right;
}

.mylabel {
    background-color: rgb(226, 214, 43);
    padding: 10px;
    margin: 10px;
    font-size: 20px;
    color: black;
}

.mytextarea {
    background-color: inherit; /* background-color: rgb(164, 248, 152) */
    padding: 5px;
    margin: 5px;
    font-size: 20px;
}

.myfooter {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: rgb(164, 248, 152);
    padding: 10px;
    margin: 10px;
    font-size: 20px;
    width: 100%;
}
.myfooter img {
    padding: 10px;
    height: 100px;
    width: 100px;
    float: left;
    margin: 0px;
}
.footer_txt {
    padding: 0px;
    margin: 0px;
    text-align: left;
}

.mynav {
    background-color: inherit; /* background-color: rgb(164, 248, 152); */
    margin-top: 50px;
    width: 350px;
    padding: 10px 20px;
    float: left;
    height: auto;
    text-align: left;
}

.mynavitem {
    cursor: pointer;
    padding: 5px 5px;
    margin-top: 10px;
    float: left;
}
.mynavitem:hover {
    text-decoration: underline;
}

.numberquestion {
    background-color: inherit; /* background-color: aqua; */
    padding: 0px;
    margin: 0 0 0 300px;
    font-size: 15px;
    float : right;
    display : inline-end
}

.messenger img {
    height: 30px;
    width: 30px;
    margin: 0px 5px;
    padding: 0px;
}