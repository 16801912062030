.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  height: 2000;
}

.myfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgb(164, 248, 152);
  padding: 10px;
  margin: 0px;
  font-size: 20px;
  width: 100%;
}
.myfooter img {
  padding: 10px;
  height: 100px;
  width: 100px;
  float: left;
  margin: 0px;
}
.footer_txt {
  padding: 0px;
  margin: 0px;
}